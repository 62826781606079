<template>
    <div>
        <validation-observer ref="" tag="form">
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">Resumen</h5>
                    <small class="text-muted">Review of the data of the day</small>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h1>Type visits</h1>
                    <vue-apex-charts type="donut" height="300" :options="optionsTV" :series="seriesTV" />
                </b-col>
                <b-col>
                    <h1>Networks</h1>
                    <vue-apex-charts type="donut" height="300" :options="optionsR" :series="seriesR" />
                    <!-- <vue-apex-charts type="pie" height="300" :options="options" :series="series" /> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <!-- total cash -->
                    <b-table stacked :fields="fields" :items="[1]">
                        <template #cell(total_patients)="data">
                            {{ totalPatients }}
                        </template>
                        <template #cell(total_cash)="data">
                            $ {{ current_balance }}
                        </template>
                        <template #cell(total_income)="data">
                            $ {{ income }}
                        </template>
                    </b-table>
                </b-col>
                <b-col>
                    <b-form-group label="Observation" label-for="observatiom">
                        <validation-provider #default="{ errors }" name="Observation" rules="required">
                            <textarea name="observatiom" id="observatiom" class="form-control"
                                v-model="cashClosing.observation" :state="errors.length > 0 ? false : null"></textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        VueApexCharts,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        dailyData: {
            type: Object,
            default: () => { },
            required: false
        },
        cashClosing: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    watch: {
        dailyData() {
            let cashes = this.dailyData?.cashes
            this.current_balance = this.dailyData?.current_balance
            this.income = this.dailyData?.income
            cashes.map(box => {
                box.movements.map(movement => {
                    this.totalPatients += 1
                    if (!movement.appointment) {
                        this.seriesTV[7] += 1
                        this.seriesR[7] += 1
                        return;
                    }
                    if (movement.appointment.patients.reference) {
                        switch (movement.appointment.patients.reference.name) {
                            case 'Radio':
                                this.seriesR[0] += 1
                                break;
                            case 'TV':
                                this.seriesR[1] += 1
                                break;
                            case 'Facebook':
                                this.seriesR[2] += 1
                                break;
                            case 'Reference':
                                this.seriesR[3] += 1
                                break;
                            case 'Sign':
                                this.seriesR[4] += 1
                                break;
                            case 'Flyers':
                                this.seriesR[5] += 1
                                break;
                            case 'Internet':
                                this.seriesR[6] += 1
                                break;
                            default:
                                this.seriesR[7] += 1
                                break;
                        }
                    } else this.seriesR[7] += 1
                    switch (movement.appointment.type_visits_id) {
                        case 1:
                            this.seriesTV[0] += 1
                            break;
                        case 2:
                            this.seriesTV[1] += 1
                            break;
                        case 3:
                            this.seriesTV[2] += 1
                            break;
                        case 4:
                            this.seriesTV[3] += 1
                            break;
                        case 5:
                            this.seriesTV[4] += 1
                            break;
                        case 6:
                            this.seriesTV[5] += 1
                            break;
                        case 7:
                            this.seriesTV[6] += 1
                            break;
                        case 8:
                            this.seriesTV[7] += 1
                            break;
                        default:
                            this.seriesTV[8] += 1
                            break;
                    }
                })
                this.optionsR.labels[0] = 'Radio: ' + this.seriesR[0]
                this.optionsR.labels[1] = 'TV: ' + this.seriesR[1]
                this.optionsR.labels[2] = 'Facebook: ' + this.seriesR[2]
                this.optionsR.labels[3] = 'Reference: ' + this.seriesR[3]
                this.optionsR.labels[4] = 'Sign: ' + this.seriesR[4]
                this.optionsR.labels[5] = 'Flyers: ' + this.seriesR[5]
                this.optionsR.labels[6] = 'Internet: ' + this.seriesR[6]
                this.optionsR.labels[7] = 'Others: ' + this.seriesR[7]
                this.optionsTV.labels[0] = 'F/U: ' + this.seriesTV[0]
                this.optionsTV.labels[1] = 'N/C: ' + this.seriesTV[1]
                this.optionsTV.labels[2] = 'N/P: ' + this.seriesTV[2]
                this.optionsTV.labels[3] = 'P/I: ' + this.seriesTV[3]
                this.optionsTV.labels[4] = 'P/P: ' + this.seriesTV[4]
                this.optionsTV.labels[5] = 'R/S: ' + this.seriesTV[5]
                this.optionsTV.labels[6] = 'V/C: ' + this.seriesTV[6]
                this.optionsTV.labels[7] = 'M/B: ' + this.seriesTV[7]
                this.optionsTV.labels[8] = 'N/A: ' + this.seriesTV[8]
            })
        }
    },
    data() {
        return {
            optionsTV: {
                labels: ['F/U: 0', 'N/C: 0', 'N/P: 0', 'P/I: 0', 'P/P: 0', 'R/S: 0', 'V/C: 0', 'M/B: 0', 'N/A: 0'],
                dataLabels: {
                    enabled: true
                }
            },
            optionsR: {
                labels: ['Radio: 0', 'TV: 0', 'Facebook: 0', 'Reference: 0', 'Sign: 0', 'Flyers: 0', 'Internet: 0', 'Others: 0'],
                dataLabels: {
                    enabled: true
                }
            },
            fields: [
                'total_patients',
                'total_cash',
                'total_income',
            ],
            totalPatients: 0,
            current_balance: 0,
            income: 0,
            seriesTV: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            seriesR: [0, 0, 0, 0, 0, 0, 0, 0],
        }
    },
}
</script>

<style></style>
