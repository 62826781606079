<template>
    <div>
        <b-row>
            <b-col cols="7" class="mb-2">
                <h5 class="mb-0">Basic Data</h5>
                <small class="text-muted">
                    See all your basic data.
                </small>
            </b-col>
            <b-col style="text-align: end;">
                <p style="overflow:hidden">
                    {{ new Date(dateBox) }}
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-table small hover :fields="fields" :items="items" responsive="sm" class="mt-2" show-empty
                    empty-text="No matching records found">
                    <template #cell(frontdesks)="data">
                        <b-row>
                            <b-col cols="auto">
                                <b-avatar size="32" :src="data.item.frontdesks.avatar" />
                            </b-col>
                            <b-col cols="auto">
                                <span class="font-weight-bold d-block text-nowrap align-items-start">
                                    <span>
                                        {{ data.item.frontdesks.name }}
                                    </span>
                                    <span>
                                        {{ data.item.frontdesks.lastname }}
                                    </span>
                                </span>
                            </b-col>
                        </b-row>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        dailyData: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    watch: {
        dailyData() {
            this.items = this.dailyData?.cashes
            this.dateBox = this.dailyData?.opening
        }
    },
    data() {
        return {
            items: [],
            dateBox: '',
            fields: [
                { key: "frontdesks", label: "Name" },
                { key: "opening", sortable: true },
                { key: "income", label: "Amount", sortable: true },
            ]
        }
    }
}
</script>

<style></style>
