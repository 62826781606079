<template>
    <div>
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Wizard-->
                <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
                    <!--begin: Wizard Nav -->
                    <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                        <div class="wizard-steps">
                            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div class="wizard-label">
                                    <i class="wizard-icon far fa-file"></i>
                                    <div>
                                        <h3 class="wizard-title">1. Diagnostics</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-history"></i>
                                    <div>
                                        <h3 class="wizard-title">2. Review</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->

                    <!--begin: Wizard Body -->
                    <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-xxl-12">
                                <form class="form" id="kt_form" ref="formNurse">
                                    <Counter class="w-25 mb-10" @finishedTime="submit" />
                                    <!--begin: Wizard Step 2-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <diagnostics :dailyData="dailyData" />
                                    </div>
                                    <!--end: Wizard Step 2-->

                                    <div class="pb-5" data-wizard-type="step-content">
                                        <review :dailyData="dailyData" :cashClosing="cashClosing" />
                                        <b-form-group label="Signature" label-for="signature">
                                            <div class="d-flex">
                                                <VueSignaturePad id="signature" width="100%" height="20vh"
                                                    ref="signaturePad" class="border border-primary rounded" :options="{
                                                        onBegin: () => {
                                                            $refs.signaturePad.resizeCanvas();
                                                        },
                                                    }" />
                                                <div class="align-self-center">
                                                    <b-button class="btn-icon align-self-center mx-1 cursor-pointer"
                                                        variant="danger" @click="clearSignature">
                                                        <i class="far fa-trash-alt"></i>
                                                    </b-button>
                                                </div>
                                            </div>
                                            <hr />
                                            <b-form-file v-model="file1" placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..." class="boton"></b-form-file>
                                        </b-form-group>
                                    </div>

                                    <!--begin: Wizard Actions -->
                                    <div class="d-flex justify-content-between border-top pt-10">
                                        <div class="mr-2">
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-prev">
                                                Previous
                                            </button>
                                        </div>
                                        <div>
                                            <button v-on:click="submit"
                                                class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-submit">
                                                Submit
                                            </button>
                                            <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-next">
                                                Next Step
                                            </button>
                                        </div>
                                    </div>
                                    <!--end: Wizard Actions -->
                                </form>
                            </div>
                            <!--end: Wizard-->
                        </div>
                    </div>
                    <!--end: Wizard Body -->
                </div>

                <ToastNotification ref="toast" />
                <!--end: Wizard-->
            </div>
        </div>

        <b-modal id="modalsito" size="xl">
            <printCash ref="printi" @attach="renombrarAttach($event)" />
        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile
} from "bootstrap-vue";
import { required, email } from "@validations";

import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import VueApexCharts from "vue-apexcharts";
import html2pdf from 'html2pdf.js';

import axiosC from "@/core/services/api/cash-account";
import axiosEC from "@/core/services/api/external-settings/cloudinary";

import Counter from "./Counter";
import boxData from "./components/boxData.vue"
import diagnostics from "./components/diagnostics.vue"
import review from "./components/review.vue"
import ToastNotification from "@/components/ToastNotification";
import NotificationMixin from "@/mixins/NotificationMixin";
import printCash from "./printCash";
export default {
    components: {
        Counter,
        ToastNotification,
        boxData,
        diagnostics,
        review,
        printCash,

        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        VueApexCharts,
        BFormFile,
    },
    mixins: [NotificationMixin],
    mounted() {
        this.getData();
        const wizard = new KTWizard("kt_wizard_v2", {
            startStep: 1, // initial active step number
            clickableSteps: true, // allow step clicking
        });

        // Change event
        wizard.on("submit", function (wizardObj, formNurse) {
            if (wizardObj.currentStep === 1) {
                /* const isValidated = this.$refs.patientRef.validate()
                console.log(isValidated); */
            }

            setTimeout(() => {
                KTUtil.scrollTop();
            }, 500);
        });
    },
    data() {
        return {
            modalShow: false,
            file1: null,
            attach: '',
            dailyData: null,
            color: "#fff",
            cashClosing: {
                cash: 0,
                observation: null,
            },
            visits: {
                series: [],
                chartOptions: {
                    chart: {
                        fontFamily: "Montserrat",
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                            },
                        },
                    },
                    labels: [],
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                            colors: ["white"],
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    value: {
                                        show: true,
                                        color: "#6ebdb7",
                                    },
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        color: "#6ebdb7",
                                        label: "Total",
                                        formatter(w) {
                                            return w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b;
                                            }, 0);
                                        },
                                    },
                                },
                            },
                        },
                    },
                    legend: { show: false },
                    comparedResult: [2, -3, 8],
                    stroke: { width: 0 },
                    colors: [
                        /* colores */
                    ],
                },
            },
            visitDate: null,
        };
    },
    methods: {
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        async renombrarAttach(urls) {
            this.attach = urls
        },
        submit: async function (e) {
            e.preventDefault();
            const { cash, observation } = this.cashClosing;
            const savePhoto = this.saveSignature();
            if (savePhoto && this.file1) {
                const { data } = this.$refs.signaturePad.saveSignature();
                const photoUrl = await this.uploadPhoto(data);
                const urlFile = await this.uploadPhoto(this.file1);
                const dataForm = new FormData()
                this.dailyData.urlFile = urlFile
                this.dailyData.photoUrl = photoUrl
                this.dailyData.observation = observation
                this.$store.state.closingCash = this.dailyData
                await this.$bvModal.show('modalsito')
                await this.$refs.printi.descargarArchivoEnPdf()
                dataForm.append('observation', observation)
                dataForm.append('register', this.dailyData.income)
                dataForm.append('signature', photoUrl)
                dataForm.append('attach', this.attach)
                this.$bvModal.hide('modalsito')
                if (this.$route.params && this.$route.params.id) {
                    axiosC.closeCashById(this.$route.params.id, dataForm).then(({ status, ...res }) => {
                        const { type, message } = NotificationMixin.methods.notificationCheck(res);
                        switch (status) {
                            case 'ok':
                                this.$refs.toast.success(message);
                                this.$store.dispatch('setClosingCash');
                                this.$router.push({ name: 'print-cash' });
                                break;
                            default:
                                this.$refs.toast.warning(message);
                                break;
                        }
                    })
                    return
                }
                axiosC.cashGlobalClose(dataForm).then(({ status, ...res }) => {
                    const { type, message } = NotificationMixin.methods.notificationCheck(res);
                    switch (status) {
                        case 'ok':
                            this.$refs.toast.success(message);
                            this.$store.dispatch('setClosingCash');
                            this.$router.push('print-cash');
                            break;
                        default:
                            this.$refs.toast.warning(message);
                            break;
                    }
                })
            } else {
                this.$refs.toast.danger("Check the data!");
            }
        },
        getData() {
            if (this.$route.params && this.$route.params.id) {
                axiosC.getBoxById(this.$route.params.id).then(({ registro, status, message }) => {
                    if (status == "ERROR") {
                        this.$refs.toast.danger(message);
                        return this.$router.go(-1)
                    }
                    this.dailyData = registro.cash
                    this.visitDate = registro.visits;
                }).catch(() => {
                    this.$router.go(-1)
                })
                return
            }
            axiosC.cashGlobalList().then(async ({ registro, status, message }) => {
                if (status == "ERROR") {
                    this.$refs.toast.danger(message);
                    return this.$router.go(-1)
                }
                this.dailyData = registro.cash
                this.visitDate = registro.visits;
            }).catch(() => {
                this.$router.go(-1)
            })

            let data = {
                nombre: [],
                cantidad: [],
                colores: [],
            };
            this.visits = {
                series: data.cantidad,
                chartOptions: {
                    labels: data.nombre,
                    colors: data.colores,
                },
            };
        },
        async uploadPhoto(file) {
            const formData = new FormData();
            formData.append("file", file);
            const { secure_url } = await axiosEC.createImage(formData);
            return secure_url;
        },
        saveSignature() {
            const { isEmpty } = this.$refs.signaturePad.saveSignature();
            return !isEmpty;
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
        validationFormInfo() {
            return new Promise((resolve, reject) => {
                this.$refs.infoRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
        validationFormAddress() {
            return new Promise((resolve, reject) => {
                this.$refs.addressRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
        validationFormSocial() {
            return new Promise((resolve, reject) => {
                this.$refs.socialRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>
